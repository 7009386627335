import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useGlobalData } from '../../contexts/GlobalData'

const BAD_IMAGES = {}

const Inline = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
`

const Image = styled.img`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
`

const StyledEthereumLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    width: ${({ size }) => size};
    height: ${({ size }) => size};
  }
`

export default function TokenLogo({ address, header = false, size = '24px', ...rest }) {
  const [error, setError] = useState(false)

  const [url, setURL] = useState('')

  const { supportedTokens } = useGlobalData()


  useEffect(() => {
    setError(false)
    BAD_IMAGES[address] = false
    for(let i in supportedTokens){
      if(address && supportedTokens[i].address.toLowerCase() == address.toLowerCase()){
        setURL(supportedTokens[i].logoURI)
      }
    }
  }, [address, supportedTokens])

  if (url == '' || error == true) {
    return (
      <Inline>
        <span {...rest} alt={''} style={{ fontSize: size }} role="img" aria-label="face">
          ❔
        </span>
      </Inline>
    )
  }

  return (
    <Inline>
      <Image
        {...rest}
        alt={''}
        src={url}
        size={size}
        onError={(event) => {
          BAD_IMAGES[address] = true
          setError(true)
          event.preventDefault()
        }}
      />
    </Inline>
  )
}
