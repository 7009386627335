import React, {useRef} from 'react'
import { useHistory } from 'react-router-dom'
import styled, {keyframes} from 'styled-components'

import { Flex } from 'rebass'
import Link from '../Link'
import { RowFixed } from '../Row'
import Logo from '../../assets/logo_white.png'

import { useToggler } from "../../hooks/useToggler";
import { useClickOutside } from "../../hooks/useClickOutside";

import { BasicLink } from '../Link'
import { useMedia } from 'react-use'

const TitleWrapper = styled.div`
  text-decoration: none;
  z-index: 10;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`

const UniIcon = styled(Link)`
  transition: transform 0.3s ease;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 2.5vw;

  height: 65px;
  :hover {
    transform: rotate(-5deg);
  }
`

const Option = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: ${({ activeText }) => (activeText ? 'var(--color-accent-blue)' : 'white')}};
  display: flex;
  margin-left: 12px;

  font-family: "Mute Logo Font";
  font-size: 14px;
  line-height: 13px;
  text-transform: uppercase;

  :hover {
    color: rgba(112,189,244,0.6);
  }

  @media (max-width: 790px) {
    font-size: 20px;
  }
`

const Icon = styled.button`
  width: 20px;
  height: 20px;
  color: white;
  fill: white;
  display: flex;
  margin-left: 12px;
  background-color: transparent;
  border: 0;

  :hover {
    fill: rgba(112,189,244,1);
  }
`

const Dim = styled.div`
  &.visible {
    position: absolute;
    width: 5000vw;
    height: 5000vh;
    background-color: rgba(0,0,0,0.4);
    z-index: 100;
  }
`
const Menu = styled.div`
  @media (max-width: 900px) {
    margin-left: 24px;
  }
  @media (max-width: 790px) {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    width: 224px;
    height: 100%;
    margin: 0;
    background: #080A11;
    z-index: 99999;
  }
  &.visible {
    display: flex;
    z-index: 99999;
    flex-direction: column;
  }
`;

const MenuHeader = styled.div`
  display: none;
  justify-content: space-between;
  align-items: center;
  padding: 16px;

  @media (max-width: 790px) {
    display: flex;
    border-bottom: 1px solid #182246;
  }
`;

const MenuContent = styled.div`
  @media (max-width: 790px) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 48px 32px 32px;
  }
`;


const FadeIn = keyframes`
 0% { opacity: 0; }
 50% { opacity: 0.5; }
 100% {opacity: 1; }
`

const FadeOut = keyframes`
 0% { opacity: 1; }
 50% { opacity: 0.5; }
 100% {opacity: 0; }
`

const MenuOpenButton = styled.button`
  width: 24px;
  height: 24px;
  margin-right: 20px;
  display: none;
  position: absolute;
  right: 0;
  background-color: transparent;
  border: 0;
  stroke: white;
  z-index: 9999999999;

  svg {
    z-index: 999999999;
  }





  :hover{
    stroke: rgba(112,189,244,1);
  }

  @media (max-width: 790px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }


`;

const MenuCloseButton = styled.button`
  margin-left: auto;
`;


const CloseIcon = styled.button`
`

const RowFixedMod = styled(RowFixed)`

@media (max-width: 790px) {
  padding-top: 50px;
  display: flex;
  flex-direction: column !important;
  justify-content: space-around;
  height: 30%;
  align-items:center;
  width: 100%;
}
`

const SocialLinks = styled(RowFixed)`
    right: 2.5vw;
    position: absolute;
    top: 10;

    @media (max-width: 790px) {
      position: relative
      padding-right: 0;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-left: auto;
      right: 0;
      left: 0;
    }
`



export default function Title() {
  const history = useHistory()
  const { visible, toggle } = useToggler()
  const ref = useRef(null)
  useClickOutside(ref, () => toggle(false))

  return (
    <TitleWrapper>
      <Flex alignItems="center" style={{ justifyContent: 'center', height: '60px', width: '100%' }}>
          <RowFixed style={{paddingLeft: '20px', position: 'absolute', top: '10', left: '0'}}>
              <UniIcon id="link" onClick={() => history.push('/')}>
                <img width={'auto'} height={'65%'} src={Logo} alt="logo" />
              </UniIcon>
          </RowFixed>




          <MenuOpenButton id='menu' onClick={() => {console.log(!visible); toggle(!visible)}} className={visible ? 'visible' : ''}>

            {visible ? (
              <svg id='menu' className={'close'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id='menu'  d="M5 5L12 12M12 12L19 19M12 12L19 5M12 12L5 19" stroke="#777F99" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            ) : (
              <svg id='menu' className={'hamburger'} width="24" height="24" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg">
                <path id='menu' d="M3 18H21"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path id='menu' d="M3 12H21"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path id='menu' d="M3 6H21"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            )}




          </MenuOpenButton>
          <Dim className={visible ? 'visible' : ''} />
          <Menu ref={ref} className={visible ? 'visible' : ''}>
            <MenuContent>
            <RowFixedMod style={{ alignItems: 'center', paddingRight: '20px' }}>
              <BasicLink to="/home">
                <Option activeText={history.location.pathname === '/home' ?? undefined}>Overview</Option>
              </BasicLink>
              <BasicLink to="/tokens">
                <Option
                  activeText={
                    (history.location.pathname.split('/')[1] === 'tokens' ||
                      history.location.pathname.split('/')[1] === 'token') ??
                    undefined
                  }
                >
                  Tokens
                </Option>
              </BasicLink>
              <BasicLink to="/pairs">
                <Option
                  activeText={
                    (history.location.pathname.split('/')[1] === 'pairs' ||
                      history.location.pathname.split('/')[1] === 'pair') ??
                    undefined
                  }
                >
                  Pairs
                </Option>
              </BasicLink>

              <BasicLink to="/accounts">
                <Option
                  activeText={
                    (history.location.pathname.split('/')[1] === 'accounts' ||
                      history.location.pathname.split('/')[1] === 'account') ??
                    undefined
                  }
                >
                  Accounts
                </Option>

              </BasicLink>

              <SocialLinks>
                  <Icon onClick={() => window.open('https://discord.gg/muteio', '_blank')}>
                  <svg width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M19.54 0c1.356 0 2.46 1.104 2.46 2.472v21.528l-2.58-2.28-1.452-1.344-1.536-1.428.636 2.22h-13.608c-1.356 0-2.46-1.104-2.46-2.472v-16.224c0-1.368 1.104-2.472 2.46-2.472h16.08zm-4.632 15.672c2.652-.084 3.672-1.824 3.672-1.824 0-3.864-1.728-6.996-1.728-6.996-1.728-1.296-3.372-1.26-3.372-1.26l-.168.192c2.04.624 2.988 1.524 2.988 1.524-1.248-.684-2.472-1.02-3.612-1.152-.864-.096-1.692-.072-2.424.024l-.204.024c-.42.036-1.44.192-2.724.756-.444.204-.708.348-.708.348s.996-.948 3.156-1.572l-.12-.144s-1.644-.036-3.372 1.26c0 0-1.728 3.132-1.728 6.996 0 0 1.008 1.74 3.66 1.824 0 0 .444-.54.804-.996-1.524-.456-2.1-1.416-2.1-1.416l.336.204.048.036.047.027.014.006.047.027c.3.168.6.3.876.408.492.192 1.08.384 1.764.516.9.168 1.956.228 3.108.012.564-.096 1.14-.264 1.74-.516.42-.156.888-.384 1.38-.708 0 0-.6.984-2.172 1.428.36.456.792.972.792.972zm-5.58-5.604c-.684 0-1.224.6-1.224 1.332 0 .732.552 1.332 1.224 1.332.684 0 1.224-.6 1.224-1.332.012-.732-.54-1.332-1.224-1.332zm4.38 0c-.684 0-1.224.6-1.224 1.332 0 .732.552 1.332 1.224 1.332.684 0 1.224-.6 1.224-1.332 0-.732-.54-1.332-1.224-1.332z"/></svg>
                  </Icon>
                  <Icon onClick={() => window.open('https://twitter.com/mute_io', '_blank')}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"/></svg>
                  </Icon>
              </SocialLinks>


            </RowFixedMod>

            </MenuContent>
          </Menu>





      </Flex>
    </TitleWrapper>
  )
}
