import { useEffect, RefObject } from 'react'

export const useClickOutside = (ref: RefObject<HTMLDivElement>, callback: () => void, deps?: any[]) => {
  const handleClick = (e: MouseEvent) => {
    if (ref.current && !ref.current.contains(e.target as HTMLInputElement) && (e.target as HTMLInputElement).id != 'menu') {
      callback()
    }
  }

  useEffect(() => {
    document.addEventListener('mouseup', handleClick)

    return () => {
      document.removeEventListener('mouseup', handleClick)
    }
  }, deps)
}
