import React, { useState } from 'react'
import styled from 'styled-components'
import { RowFixed, RowBetween } from '../Row'
import { useMedia } from 'react-use'
import { useGlobalData, useEthPrice } from '../../contexts/GlobalData'
import { formattedNum, localNumber, formattedPercent } from '../../utils'
import { Box } from 'rebass'
import Panel from '../Panel'
import { AutoColumn } from '../Column'

import UniPrice from '../UniPrice'
import { TYPE } from '../../Theme'

const Header = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
`

const Medium = styled.span`
  font-weight: 500;
`

export default function GlobalStats() {
  const below400 = useMedia('(max-width: 700px)')

  const [showPriceCard, setShowPriceCard] = useState(false)

  const { oneDayVolumeUSD, oneDayTxns, pairCount } = useGlobalData()
  const [ethPrice] = useEthPrice()
  const formattedEthPrice = ethPrice ? formattedNum(ethPrice, true) : '-'
  const oneDayFees = oneDayVolumeUSD ? formattedNum(oneDayVolumeUSD * 0.005, true) : ''

  return (
    <Header>
      {below400 ? (
        <Box mb={20}>
          <Panel>
            <Box>
              <AutoColumn gap="36px">
                <AutoColumn gap="20px">
                  <RowBetween>
                    <TYPE.main style={{width: '50%'}}>ETH Price</TYPE.main>
                    <TYPE.main style={{width: '50%', textAlign: 'right'}}>Transactions (24H)</TYPE.main>
                  </RowBetween>
                  <RowBetween align="flex-end">
                    <TYPE.main fontSize={'1.5rem'} lineHeight={1} fontWeight={600} style={{width: '50%'}}>{formattedEthPrice}</TYPE.main>
                    <TYPE.main fontSize={'1.5rem'} lineHeight={1} fontWeight={600} style={{width: '50%', textAlign: 'right'}}>{localNumber(oneDayTxns)}</TYPE.main>
                  </RowBetween>
                </AutoColumn>
              </AutoColumn>
            </Box>
            <br/>
            <br/>
            <Box>
              <AutoColumn gap="36px">
                <AutoColumn gap="20px">
                  <RowBetween>
                    <TYPE.main style={{width: '50%', textAlign: 'left'}}>Pairs</TYPE.main>
                    <TYPE.main style={{width: '50%', textAlign: 'right'}}>Fees (24H)</TYPE.main>
                  </RowBetween>
                  <RowBetween align="flex-end">
                    <TYPE.main fontSize={'1.5rem'} lineHeight={1} fontWeight={600} style={{width: '50%', textAlign: 'left'}}>{localNumber(pairCount)}</TYPE.main>
                    <TYPE.main fontSize={'1.5rem'} lineHeight={1} fontWeight={600} style={{width: '50%', textAlign: 'right'}}>{oneDayFees}</TYPE.main>
                  </RowBetween>
                </AutoColumn>
              </AutoColumn>
            </Box>
          </Panel>
        </Box>
      ) : (
        <Box mb={20}>
          <Panel>
            <Box>
              <AutoColumn gap="36px">
                <AutoColumn gap="20px">
                  <RowBetween>
                    <TYPE.main style={{width: '25%'}}>ETH Price</TYPE.main>
                    <TYPE.main style={{width: '25%', textAlign: 'center'}}>Transactions (24H)</TYPE.main>
                    <TYPE.main style={{width: '25%', textAlign: 'center'}}>Pairs</TYPE.main>
                    <TYPE.main style={{width: '25%', textAlign: 'right'}}>Fees (24H)</TYPE.main>
                  </RowBetween>
                  <RowBetween align="flex-end">
                    <TYPE.main fontSize={'1.5rem'} lineHeight={1} fontWeight={600} style={{width: '25%'}}>{formattedEthPrice}</TYPE.main>
                    <TYPE.main fontSize={'1.5rem'} lineHeight={1} fontWeight={600} style={{width: '25%', textAlign: 'center'}}>{localNumber(oneDayTxns)}</TYPE.main>
                    <TYPE.main fontSize={'1.5rem'} lineHeight={1} fontWeight={600} style={{width: '25%', textAlign: 'center'}}>{localNumber(pairCount)}</TYPE.main>
                    <TYPE.main fontSize={'1.5rem'} lineHeight={1} fontWeight={600} style={{width: '25%', textAlign: 'right'}}>{oneDayFees}</TYPE.main>
                  </RowBetween>
                </AutoColumn>
              </AutoColumn>
            </Box>
          </Panel>
        </Box>
      )}


    </Header>
  )
}
