import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'

var graphurl = 'https://api.goldsky.com/api/public/project_clmtie4nnezuh2nw6hhjg6mo7/subgraphs/mute_switch/v0.0.7/gn'
//https://api.studio.thegraph.com/query/12332/muteswitch---zksync-era/v0.0.20
//https://api.studio.thegraph.com/query/12332/muteswitch---zksync-era/v0.0.20
export const client = new ApolloClient({
  link: new HttpLink({
    uri: graphurl
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const stakingClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/subgraphs/name/way2rach/talisman',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const blockClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.studio.thegraph.com/query/12332/blocks---zksync-era/version/latest'
  }),
  cache: new InMemoryCache(),
})